import React from 'react'
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader'
// import sections
import HeroFull from '../components/sections/HeroFull'
import FeaturesTiles from '../components/sections/FeaturesTiles'
import GenericSection from '../components/sections/GenericSection'
import DescriptionSplit from '../components/sections/DescriptionSplit'
import FeatureSplit from '../components/sections/FeaturesSplit'
import Button from '../components/elements/Button'
import ButtonGroup from '../components/elements/ButtonGroup'
import CtaRegister from '../components/sections/CtaRegister'

import Pricing from '../components/sections/Pricing'
import Accordion from '../components/elements/Accordion'
import AccordionItemWithCheck from '../components/elements/AccordionItemWithCheck'
import ReactCanvasConfetti from 'react-canvas-confetti'
import PlausibleGoalCall from '../utils/Plausible'
import { withTranslation } from 'react-i18next'
import LoginForm from '../components/sections/LoginForm'



const confettiStyle = {
  position: 'fixed',
  width: '100%',
  height: '120%',
  zIndex: -1
}

class Home extends React.Component {
  constructor(props) {
    super(props)

    let tempClickedTiles = new Map()
    tempClickedTiles.set("protection", false)
    tempClickedTiles.set("spam", false)
    tempClickedTiles.set("privacy", false)
    tempClickedTiles.set("consciousness", false)
    tempClickedTiles.set("usability", false)
    tempClickedTiles.set("simplicity", false)
    tempClickedTiles.set("tech", false)
    tempClickedTiles.set("smart", false)

    this.state = {
      videoModalActive: false,
      clickedTiles: tempClickedTiles,
      clickedTilesSelected: false
    }
  }

  onClickDefault = () => {
    // starting the animation
    this.confetti()
  }


  getInstance = (instance) => {
    // saving the instance to an internal property
    this.confetti = instance
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({ videoModalActive: true })
  }

  closeModal = (e) => {
    e.preventDefault()
    this.setState({ videoModalActive: false })
  }

  updateAccordion = (key, value) => {
    console.debug("Home.updateAccordion - key", key, "value", value)
    let tmpClickedTiles = this.state.clickedTiles
    tmpClickedTiles.set(key, value)
    console.debug("Home.updateAccordion - tmpClickedTiles", tmpClickedTiles)
    this.setState({ clickedTiles: tmpClickedTiles })
  }

  render() {

    const { t } = this.props

    console.debug("Home.render - clickedTiles", this.state.clickedTiles)
    console.debug("Home.render - this.state.clickedTiles.get('spam')", this.state.clickedTiles.get("spam"))


    return (
      <React.Fragment>
        <ReactCanvasConfetti
          // set the styles as for a usual react component
          style={confettiStyle}
          // set the class name as for a usual react component
          className={'confetti'}
          // set the callback for getting instance. The callback will be called after initialization ReactCanvasConfetti component
          refConfetti={this.getInstance}
        />
        <HeroFull className="illustration-section-01" />
        <DescriptionSplit imageFill />
        <CtaRegister split claim={<>{t("banner.1")}</>} track={() => {
          PlausibleGoalCall("ClickBanner1")
        }} />
        <FeatureSplit sectionHeader={{ title: "Features", sectionName: "features" }} />
        <CtaRegister split claim={<>{t("banner.2.1")}<br />{t("banner.2.2")}<br />{t("banner.2.3")}</>} track={() => {
          PlausibleGoalCall("ClickBanner2")
        }} />
        <Pricing />
        <CtaRegister split claim={<>{t("banner.3")}</>} track={() => {
          PlausibleGoalCall("ClickBanner3")
        }} />
      </React.Fragment>
    )
  }
}


const ClickOrderedAccordion = ({ clickedTilesState }) => {
  const items = new Map()
  items.set("protection", <>
    <AccordionItemWithCheck title="Schutz" checked={clickedTilesState.get("protection")} >
      Mit Singlemails schützt du deine ehrliche private E-Mail. Behalte deine private E-Mail für dich und rücke stattdessen deine Singlemails E-Mail-Adresse heraus, wo immer eine E-Mail von dir erforderlich ist. Somit reduzierst du dein Risiko, dass deine private E-Mail in die falschen Hände gerät. Das schützt dich wiederum vor den Auswirkungen nach Datenlecks.
    </AccordionItemWithCheck></>)
  items.set("spam",
    <AccordionItemWithCheck title="Spam" checked={clickedTilesState.get("spam")}>
      Mithilfe deiner Singlemails kannst du SPAM entgegenwirken. Falls du irgendwann über deine Singlemails E-Mail-Adresse SPAM erhalten solltest, kannst du sie einfach löschen und eine neue Singlemails Adresse erzeugen. Deine ehrliche und liebgewonnene E-Mail Adresse bleibt unberührt davon. Zusätzlich weißt du welcher Anbieter die Quelle des SPAM ist.
    </AccordionItemWithCheck>)
  items.set("privacy",
    <AccordionItemWithCheck title="Privatsphäre" checked={clickedTilesState.get("privacy")}>
      Wie deine Adresse und Telefonnummer gehört deine private E-Mail-Adresse zu deinen sensitiven Daten und ist Teil deiner Privatsphäre. Teilst du deine sensitiven Daten gerne mit jedermann? Oder versuchst du die Verteilung deiner sensitiven Daten auf ein Minimum zu reduzieren? Dasselbe solltest du für deine E-Mail-Adresse bedenken. Singlemails unterstützt dich dabei deine E-Mail nicht überall angeben zu müssen, stattdessen nutzt du eine maskierte Singlemails E-Mail-Adresse.
    </AccordionItemWithCheck>)
  items.set("consciousness",
    <AccordionItemWithCheck title="Bewusstsein" checked={clickedTilesState.get("consciousness")}>
      Angefangen mit deiner privaten E-Mail-Adresse, machst du mit Singlemails einen großen und wichtigen Schritt in die Datensparsamkeit. Dir sollte bewusst sein, dass deine E-Mail zu deinen persönlichen Daten gehört und eine sparsame Weitergabe das Risiko von Missbrauch reduziert. Falls ein  Anbieter deine Singlemails E-Mail-Adresse ungefragt weiter geben sollte, dann wirst du es schnell herausfinden. Denn jede Singlemails Adresse ist einzigartig und sollte nur bei einem Anbieter hinterlegt sein. Gemäß dem Motto: "Vertrauen ist gut, Kontrolle ist besser".
    </AccordionItemWithCheck>)
  items.set("usability",
    <AccordionItemWithCheck title="Anwendbarkeit" checked={clickedTilesState.get("usability")}>
      Deine private E-Mail-Adresse sollst du weiterhin benutzen. Denn alle E-Mails die an deine Singlemails Adressen gehen, werden direkt zu deiner privaten E-Mail-Adresse weitergeleitet. Aus dem Betreff der weitergeleiteten Singlemails E-Mail siehst du auf einem Blick von wo die original E-Mail stammt.
    </AccordionItemWithCheck>)
  items.set("simplicity",
    <AccordionItemWithCheck title="Simple" checked={clickedTilesState.get("simplicity")}>
      Singlemails ist einfach gehalten und benutzerfreundlich gestaltet. Jedem Benutzer ist es möglich schnell und ohne technischen Vorkenntnissen Singlemails zu nutzen. Das Erstellen einer neuen Singlemails E-Mail-Adresse dauert nur ein paar Sekunden.
    </AccordionItemWithCheck>)
  items.set("tech",
    <AccordionItemWithCheck title="Ich bin kein Techie" checked={clickedTilesState.get("tech")}>
      Du musst nicht im Chaos Computer Club Mitglied sein, um Singlemails nutzen zu können. Singlemails ist für alle! Beeindrucke deine Nerd-Freunde mit deiner neu erhaltenen Privatsphäre und hunderten neuer Singlemails E-Mail-Adressen.
    </AccordionItemWithCheck>)
  items.set("smart",
    <AccordionItemWithCheck title="Ich bin smart!" checked={clickedTilesState.get("smart")}>
      Natürlich bist du smart, da dir deine private E-Mail-Adresse etwas bedeutet und du sie schützen möchtest. Probiere es einfach aus. Deinen nächsten Account erstellst du einfach mit Singlemails.
    </AccordionItemWithCheck>)

  console.debug("ClickOrderedAccordion - clickedTilesState", clickedTilesState, "items", items.entries)


  let array = Array.from(items, ([name, value]) => {
    if (value === true) {

      return { name, value }
    }
  })

  return (
    <Accordion>
      {//render all clicked items
        Array.from(clickedTilesState, ([name, value]) => {
          if (value === true) {
            return items.get(name)
          }
        })
      }
      {//render all unclicked items
        Array.from(clickedTilesState, ([name, value]) => {
          if (value === false) {
            return items.get(name)
          }
        })
      }
    </Accordion >
  )

}

export default withTranslation()(Home)