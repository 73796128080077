import React from "react"
import classNames from "classnames"
import { withTranslation } from 'react-i18next'
import { SectionSplitProps } from "../../utils/SectionProps"
import SectionHeader from "./partials/SectionHeader"
import Image from "../elements/Image"

import PhoneCreate from "./../../assets/images/singlemails/features/iPhoneX_create_email.png"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      sectionHeader,
      t,
      ...props
    } = this.props

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    )

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    )

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    )

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item reveal-from-left">
                <div
                  className="split-item-content center-content-mobile"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12" name="description" >{t("features.headerSpam.1")}<span className="green-text">{t("features.headerSpam.2")}</span></h3>
                  <p className="m-0">
                    {t("features.textSpam")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/pause_resume.gif")}
                    alt="Features split 01"
                    style={{
                      width: "350px", maxWidth: "none",
                      display: "block", marginLeft: "auto", marginRight: "auto",
                      borderRadius: "9px",
                      color: "#17c297",
                      borderStyle: "solid",
                      boxShadow: "0 15px 25px 0 rgba(23, 194, 151, 0.15), 0 5px 10px 0 rgba(34, 42, 63, 0.05)",
                    }} />
                </div>
              </div>

              <div className="split-item reveal-from-left">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12"><span className="green-text">{t("features.headerReply.1")}</span>{t("features.headerReply.2")}</h3>
                  <p className="m-0">
                    {t("features.textReply")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image split-item-image2 center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/reply.png")}
                    alt="phone_create"
                    style={{
                      width: "300px"
                    }}
                  />
                </div>
              </div>



              <div className="split-item reveal-from-left">
                <div
                  className="split-item-content center-content-mobile"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12" name="description" ><span className="green-text">{t("features.headerEasy.1")}</span>{t("features.headerEasy.2")}</h3>
                  <p className="m-0">
                    {t("features.textEasy")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/iPhoneX_Dashboard.png")}
                    alt="Singlemails Phone List"
                    style={{ width: "200px", maxWidth: "none" }}
                  />
                </div>
              </div>

              <div className="split-item reveal-from-right">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("features.headerPrivacy.1")}
                    <span className="green-text">{t("features.headerPrivacy.2")}</span></h3>
                  <p className="m-0">
                    {t("features.textPrivacy")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image split-item-image2 center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/MacPro_createEmail_small.png")}
                    alt="Features split 01"
                    style={{ width: "600px" }}
                  />
                </div>
              </div>

              <div className="split-item reveal-from-right">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12"><span className="green-text">{t("features.headerNoWork.1")}</span>{t("features.headerNoWork.2")}</h3>
                  <p className="m-0">
                    {t("features.textNoWork")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image split-item-image2 center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/MacPro_Dashboard_small.png")}
                    alt="phone_create"
                    style={{ width: "600px" }}
                  />
                </div>
              </div>

              <div className="split-item reveal-from-left">
                <div
                  className="split-item-content center-content-mobile"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12" name="description" >
                    {t("features.headerAds.1")}<span className="green-text">{t("features.headerAds.2")}</span></h3>
                  <p className="m-0">
                    {t("features.textAds")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/features/low-vision-color.svg")}
                    alt="Features split 01"
                    style={{ width: "300px"}}
                  />
                </div>
              </div>

              {/* <div className="split-item reveal-from-right">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">Funktioniert überall</h3>
                  <p className="m-0">
                    Nutze unsere Progressiv Web App im Browser oder lade sie dir aufs Handy.
                    Singlemails funktioniert überall.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image split-item-image2 center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/feature-tile-icon-06.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FeaturesSplit.propTypes = propTypes
FeaturesSplit.defaultProps = defaultProps

export default withTranslation()(FeaturesSplit)
