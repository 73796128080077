import React from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import Image from "../../elements/Image"
import { useTranslation } from 'react-i18next'


const Logo = ({ className, showLanguage, ...props }) => {
  const classes = classNames("brand", className)

  return (
    <>
      <div {...props} className={classes}>
        <h1 className="m-0">
          <Link to="/">
            <Image
              src={require("./../../../assets/images/singlemails/singlemailsLogoLight.png")}
              alt="Cube"
              width={125}
              height={84}
            />
          </Link>
        </h1>
      </div>
      {showLanguage && <Language />}
    </>
  )
}


const Language = () => {

  const { t, i18n } = useTranslation()
  console.debug("i18n object -", i18n)

  if (i18n?.language === "en") {
    return (
      <>
        <div onClick={() => { i18n.changeLanguage("de") }} style={{ cursor: 'pointer' }}>🇩🇪</div>
      </>
    )
  }
  return (
    <>
      <div onClick={() => { i18n.changeLanguage("en") }} style={{ cursor: 'pointer' }}>🇺🇸</div>
    </>
  )
}


export default Logo
