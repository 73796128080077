import React from 'react'
import Pricing from '../components/sections/Pricing'



const style = {
  position: 'fixed',
  width: '100%',
  height: '120%',
  zIndex: -1
}

class Home extends React.Component {

  openModal = (e) => {
    e.preventDefault()
    this.setState({ videoModalActive: true })
  }

  closeModal = (e) => {
    e.preventDefault()
    this.setState({ videoModalActive: false })
  }


  render() {  
    return (
      <React.Fragment>
         <Pricing />
      </React.Fragment>
    )
  }
}

export default Home