import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const FooterNav = ({
  className,
  ...props
}) => {

  const { t } = useTranslation();

  const classes = classNames(
    'footer-nav',
    className
  )

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          {/* <Link to="/contact/">Contact</Link> */}
          <a href="mailto:info@singlemails.de">{t("footer.contact")}</a>
        </li>
        {/* <li>
          <Link to="/about-us/">About us</Link>
        </li> */}
        {/* <li>
          <Link to="/faqs/">FAQ's</Link>
        </li> */}
        {/* <li>
          <Link to="/support/">Support</Link>
        </li> */}
        <li>
          <Link to="/imprint/">{t("footer.imprint")}</Link>
        </li>
        <li>
          <Link to="/privacy/">{t("footer.dataPrivacy")}</Link>
        </li>
        <li>
          <Link to="/terms/">{t("footer.terms")}</Link>
        </li>
      </ul>
    </nav>
  )
}

export default FooterNav