import React, {useEffect} from 'react'
import GenericSection from '../components/sections/GenericSection'


const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <GenericSection topDivider>
                <div className="container">

                    <h2 className="mt-0">Geschäftsbedingungen</h2>
                    <p>Letzte Aktualisierung:  28. Mai 2021</p>
                    <p>
                        Diese Allgemeinen Geschäftsbedingungen ("AGB", "Geschäftsbedingungen") regeln Ihre Beziehung zu der Singlemails Anwendung (der "Service"), die von der SingleGrey GmbH ("uns", "wir" oder "unsere") betrieben wird.
                    </p>
                    <p>
                        Bitte lesen Sie diese Geschäftsbedingungen sorgfältig durch, bevor Sie unsere Anwendung Singlemails (den "Service") verwenden.
                    </p>
                    <p>
                        Ihr Zugang zu und die Nutzung des Dienstes setzen Ihre Annahme und die Einhaltung dieser Bedingungen voraus. Diese Bedingungen gelten für alle Besucher, Nutzer und andere Personen, die den Service nutzen oder auf ihn zugreifen.
                    </p>
                    <p>
                        Durch den Zugriff auf oder die Nutzung des Dienstes erklären Sie sich einverstanden, an diese Bedingungen gebunden zu sein. Wenn Sie mit einem Teil der Bedingungen nicht einverstanden sind, dürfen Sie nicht auf den Service zugreifen.
                    </p>

                    <h2 className="mt-0">Inhalte</h2>
                    <p>
                        Unser Service ermöglicht es Ihnen, Ihre persönliche E-Mail-Adresse durch unseren Service mithilfe von E-Mail-Maskierung zu schützen. Sie sind für den Inhalt verantwortlich, den Sie an den Dienst senden bzw. über unseren Service erhalten, einschließlich seiner Rechtmäßigkeit, Zuverlässigkeit und Angemessenheit.
                    </p>
                    <p>
                        Indem Sie Inhalte an den Service senden oder Inhalte durch den Service weitergeleitet bekommen, gewähren Sie uns das Recht und die Lizenz, diese Inhalte auf dem und durch den Service zu nutzen. Sie behalten sämtliche Rechte an den von Ihnen übermittelten Inhalten, die Sie auf dem oder durch den Service einreichen, und Sie sind für den Schutz dieser Rechte verantwortlich.
                    </p>
                    <p>
                        Sie erklären und garantieren, dass: (I) der Inhalt Ihrer ist (Sie besitzen ihn), oder Sie haben das Recht, ihn zu nutzen und uns die Rechte und Lizenzen gemäß diesen Nutzungsbedingungen zu gewähren, und (II) die Veröffentlichung Ihrer Inhalte auf oder durch den Service nicht die Rechte, die Privatsphäre, Veröffentlichungsrechte, Urheberrechte, Vertragsrechte oder andere Rechte einer Person verletzen.
                    </p>

                    <h2 className="mt-0">Konten</h2>
                    <p>
                        Wenn Sie ein Konto bei uns erstellen, müssen Sie uns Informationen, die jederzeit korrekt, vollständig und aktuell sind, angeben. Die Nichteinhaltung dieser Bedingungen stellt einen Verstoß gegen die Nutzungsbedingungen dar, der zur sofortigen Kündigung Ihres Kontos auf unserem Service führen kann.
                    </p>
                    <p>
                        Sie sind verantwortlich für die Sicherung des Kennworts, das Sie für den Zugriff auf den Dienst verwenden, und für alle Aktivitäten oder Aktionen unter Ihrem Kennwort, unabhängig davon, ob Ihr Kennwort für unseren Dienst oder einen Drittanbieter-Dienst erstellt wurde.
                    </p>
                    <p>
                        Sie stimmen zu, Ihr Passwort nicht an Dritte weiterzugeben und Sie müssen uns unverzüglich benachrichtigen, wenn Sie Kenntnis von einer Verletzung der Sicherheit oder der unbefugten Nutzung Ihres Kontos erhalten.
                    </p>
                    <p>
                        Sie dürfen nicht als Benutzernamen verwenden: den Namen einer anderen Person oder Einrichtung, der nicht rechtmäßig zur Nutzung zur Verfügung steht; einen Namen oder eine Marke ohne entsprechende Genehmigung, für die die Rechte anderen Personen oder Organisationen als Ihnen zustehen; oder einen Namen, der anstößig, vulgär oder obszön ist.
                    </p>
                    <p>
                        Der massenhafte Versand von E-Mails über den Service, soweit es sich um Werbe- bzw. unerwünschter E-Mail (nachfolgend als „SPAM“ bezeichnet) handelt, ist untersagt. Wir behalten uns das Recht vor E-Mail-Adressen und Konten zu sperren, sofern wir eine unerlaubte Nutzung feststellen.
                    </p>
                    <p>
                        Kostenfreie Tarife dürfen nur von Privatpersonen und ausschließlich für den privaten Gebrauch verwendet werden. Die geschäftliche Nutzung ist ausschließlich im kostenpflichtigen Abonnement erlaubt.
                    </p>

                    <h2 className="mt-0">Geistiges Eigentum</h2>
                    <p>
                        Der Dienst und sein ursprünglicher Inhalt (ohne die von den Benutzern bereitgestellten Inhalte), seine Funktionen und Funktionalitäten sind und bleiben Eigentum der SingleGrey GmbH und seinen Lizenzgebern. Der Service ist durch Copyright, Warenzeichen und andere Gesetze sowohl von Hamburg, Deutschland als auch anderer Länder geschützt. Unsere Marken und Handelsbezeichnungen dürfen ohne vorherige schriftliche Zustimmung von der SingleGrey GmbH nicht in Verbindung mit Produkten oder Dienstleistungen verwendet werden.
                    </p>
                    <h2 className="mt-0">Links zu anderen Webseiten
</h2>
                    <p>
                        Unser Service kann Links zu Websites von Drittanbietern oder Diensten enthalten, die nicht Eigentum von SingleGrey GmbH sind.
                    </p>
                    <p>
                        Die SingleGrey GmbH hat keine Kontrolle darüber und übernimmt keinerlei Verantwortung für den Inhalt, die Datenschutzbestimmungen oder Praktiken von Webseiten oder Diensten Dritter. Sie erklären sich ferner damit einverstanden, dass Singlemails weder direkt noch indirekt für irgendwelche Schäden oder Verluste verantwortlich oder haftbar ist, die durch oder im Zusammenhang mit der Nutzung oder dem Vertrauen auf solche Inhalte, Waren oder Dienstleistungen auf oder durch solche Webseiten oder Dienste verursacht wurden.
                    </p>
                    <p>
                        Wir empfehlen Ihnen dringend, die Nutzungsbedingungen und Datenschutzrichtlinien von Drittanbieter-Websites oder Diensten, die Sie besuchen, zu lesen.
                    </p>
                    <h2 className="mt-0">Kündigung</h2>
                    <p>
                        Wir können Ihr Konto unverzüglich, ohne vorherige Ankündigung oder Haftung, aus jedwedem Grund, ohne jegliche Einschränkung, wenn Sie gegen die AGBs verstoßen, kündigen oder sperren.
                    </p>
                    <p>
                        Bei Kündigung wird Ihr Nutzungsrecht sofort eingestellt. Wenn Sie Ihr Konto kündigen möchten, können Sie die Nutzung des Dienstes einfach beenden.
                    </p>

                    <h2 className="mt-0">Haftungsbeschränkung</h2>
                    <p>
                        In keinem Fall haften Singlemails sowie ihre Direktoren, Mitarbeiter, Partner, Vertreter, Lieferanten oder verbundene Unternehmen für indirekte, zufällige, besondere, Folgeschäden oder Straf- / Entschädigungszahlungen, einschließlich Gewinne, Firmenwerte oder sonstige immaterielle Verluste, die sich aus (I) Ihrem Zugang zu oder dem Gebrauch oder der Unfähigkeit des Zugangs oder der Nutzung des Dienstes ergeben; (II) irgendein Verhalten oder Inhalt eines Dritten im Dienst; (III) Inhalte, die aus dem Dienst erworben wurden; und (IV) unberechtigter Zugriff, Nutzung oder Veränderung Ihrer Übertragungen oder Inhalte, sei es auf Garantie, Vertrag, unerlaubter Handlung (einschließlich Fahrlässigkeit) oder einer anderen gesetzlichen Norm, ob wir oder ob wir nicht über die Möglichkeit eines solchen Schadens unterrichtet worden sind oder auch falls eine hierin enthaltene Abhilfe ihren wesentlichen Zweck verfehlt hat.
                    </p>


                    <h2 className="mt-0">Haftungsausschluss
</h2>
                    <p>
                        Ihre Nutzung des Service erfolgt auf eigenes Risiko. Der Service wird auf einer "AS IS" und "AS AVAILABLE" Basis zur Verfügung gestellt. Der Service erfolgt ohne jegliche Gewährleistung, weder ausdrücklich noch stillschweigend, einschließlich, aber nicht beschränkt auf implizite Garantien der Marktgängigkeit, der Eignung für einen bestimmten Zweck, der Nichtverletzung eines Rechtes oder bestimmter Eigenschaften bezüglich der Leistung.
                    </p>
                    <p>
                        SingleGrey ihre Tochtergesellschaften, verbundene Unternehmen und ihre Lizenzgeber geben keine Garantie, dass a) der Dienst ununterbrochen, sicher oder zu einem bestimmten Zeitpunkt oder an einem bestimmten Ort funktionieren wird; b) dass Fehler oder Mängel berichtigt werden; C) der Service frei von Viren oder anderen schädlichen Bestandteilen ist; oder d) die Ergebnisse der Nutzung des Dienstes Ihren Anforderungen entsprechen werden.
                    </p>

                    <h2 className="mt-0">Geltendes Recht</h2>
                    <p>
                        Diese Bedingungen richten sich nach den Gesetzen von Hamburg, Deutschland ohne Berücksichtigung von Kollisionsnormen.
                    </p>
                    <p>
                        Unsere Nichteinforderung eines Rechts oder einer Bestimmung dieser Bedingungen wird nicht als Verzicht auf diese Rechte angesehen. Sollte eine Bestimmung dieser Bedingungen von einem Gericht als ungültig oder undurchführbar angesehen werden, so bleiben die übrigen Bestimmungen dieser Bedingungen wirksam. Diese Bedingungen bilden die gesamte Vereinbarung zwischen uns über unseren Service und ersetzen alle früheren Vereinbarungen, die eventuell zwischen uns bezüglich des Service bestehen könnten.
                    </p>

                    <h2 className="mt-0">Änderungen</h2>
                    <p>
                        Wir behalten uns das Recht vor, diese Bedingungen nach eigenem Ermessen jederzeit zu ändern oder zu ersetzen. Wenn eine Überarbeitung / Änderung wesentlich ist, werden wir angemessene Anstrengungen unternehmen, um die Änderung mit einer Frist von mindestens 30 Tagen vor dem Wirksamwerden neuer Bestimmungen anzukündigen. Was eine wesentliche Änderung darstellt, wird nach unserem eigenen Ermessen bestimmt.
                    </p>
                    <p>
                        Wenn Sie unseren Service weiterhin nutzen oder nutzen, nachdem diese Änderungen wirksam geworden sind, erklären Sie sich damit einverstanden, an die überarbeiteten Bestimmungen gebunden zu sein. Wenn Sie mit den neuen Bestimmungen nicht ganz oder teilweise nicht einverstanden sind, beenden Sie bitte die Nutzung der Webseite und des Service.
                    </p>

                    <h2 className="mt-0">Kontakt</h2>
                    <p>
                        Falls Sie Fragen zu diesen Bedingungen haben, kontaktieren Sie uns bitte.
                    </p>
                </div>
            </GenericSection>
        </>
    )

}


export default Terms