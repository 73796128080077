import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { SectionTilesProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Switch from '../elements/Switch'
import Button from '../elements/Button'
import PlausibleGoalCall from '../../utils/Plausible'

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false
}

class Pricing extends React.Component {

  state = {
    priceChangerValue: "0",
    priceInput: {
      // 0: "1,000",
      // 1: "1,250"
    },
    priceOutput: {
      plan1: {
        0: ["", this.props.t("price.gold.price"), ""]
        // 1: ["€", "34", "/m"],
      },
      plan2: {
        0: ["", this.props.t("price.diamond.price"), ""]
        // 1: ["€", "54", "/m"],
      },
      plan3: {
        0: [this.props.t("price.company.price"), "", ""]
        // 1: ["€", "74", "/m"],
      }
    }
  }

  slider = React.createRef();
  sliderValue = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      this.slider.current.setAttribute('min', 0)
      this.slider.current.setAttribute('max', Object.keys(this.state.priceInput).length - 1)
      this.thumbSize = parseInt(window.getComputedStyle(this.sliderValue.current).getPropertyValue('--thumb-size'), 10)
      this.handleSliderValuePosition(this.slider.current)
    }
  }

  handlePricingSwitch = (e) => {
    this.setState({ priceChangerValue: e.target.checked ? '1' : '0' })
  }

  handlePricingSlide = (e) => {
    this.setState({ priceChangerValue: e.target.value })
    this.handleSliderValuePosition(e.target)
  }

  handleSliderValuePosition = (input) => {
    const multiplier = input.value / input.max
    const thumbOffset = this.thumbSize * multiplier
    const priceInputOffset = (this.thumbSize - this.sliderValue.current.clientWidth) / 2
    this.sliderValue.current.style.left = input.clientWidth * multiplier - thumbOffset + priceInputOffset + 'px'
  }

  getPricingData = (values, set) => {
    return set !== undefined ? values[this.state.priceChangerValue][set] : values[this.state.priceChangerValue]
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      t,
      ...props
    } = this.props

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    )

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    )

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    )

    const sectionHeader = {
      title: t("price.intro.title"),
      paragraph: t("price.intro.paragraph"),
      sectionName: 'price'
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" sectionName="price" />
            {pricingSwitcher &&
              <div className="pricing-switcher center-content">
                <Switch
                  checked={this.state.priceChangerValue === '1' ? true : false}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually">
                  Billed Monthly
                </Switch>
              </div>
            }
            {pricingSlider &&
              <div className="pricing-slider center-content">
                <label className="form-slider">
                  <span className="mb-16">How many users do you have?</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handlePricingSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="pricing-slider-value">
                  {this.getPricingData(this.state.priceInput)}
                </div>
              </div>
            }
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {t("price.gold.price")}
                        </span>
                        <span className="pricing-item-price-after text-xs">
                        </span>
                      </div>
                      <div className="text-xs text-color-low">
                        {t("price.gold.title")} &#127941;
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        {t("price.gold.paragraph")}<br />&nbsp;
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">{t("price.items.create.1")}<br />{t("price.items.create.2")}<br />{t("price.items.create.3")}</li>
                        <li className="is-checked">
                          <div>{t("price.items.active.1.limited.1")}<span className="text-color-high fw-700">{t("price.items.active.1.limited.2")}</span>{t("price.items.active.2")}<br />{t("price.items.active.3")}
                          </div>
                        </li>
                        <li className="is-checked">{t("price.items.redirect")}</li>
                        <li className="is-checked">{t("price.items.adTracking")}</li>
                        <li className="is-checked">
                          <div>
                            <span className="text-color-high fw-700">{t("price.items.deactivate.1")}</span>{t("price.items.deactivate.2")}<br />{t("price.items.deactivate.3")}
                            <span className="text-color-high fw-700">{t("price.items.deactivate.4")}</span><br />{t("price.items.deactivate.5")}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href={process.env.REACT_APP_SIGNUP_ENDPOINT} target="_blank" rel="noopener noreferrer" onClick={() => PlausibleGoalCall("ClickPricing")}>{t("price.gold.button")}</Button>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        </span>
                        <span className="pricing-item-price-amount h2">
                          {t("price.diamond.price.1")}
                        </span><br />
                        <span className="pricing-item-price-amount h5">
                          {t("price.diamond.price.2")}
                        </span>
                        <span className="pricing-item-price-after text-xs">
                        </span>
                      </div>
                      <div className="text-xs text-color-low">
                        {t("price.diamond.title")} &#128142;
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        {t("price.diamond.paragraph")}<br />&nbsp;
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">{t("price.items.create.1")}<br />{t("price.items.create.2")}<br />{t("price.items.create.3")}</li>
                        <li className="is-checked">
                          <div>
                            <span className="text-color-high fw-700">{t("price.items.active.1.unlimited.1")}</span>{t("price.items.active.1.unlimited.2")}{t("price.items.active.2")}<br />{t("price.items.active.3")}
                          </div>
                        </li>
                        <li className="is-checked">{t("price.items.redirect")}</li>
                        <li className="is-checked">{t("price.items.adTracking")}</li>
                        <li className="is-checked">
                          <div>
                            <span className="text-color-high fw-700">{t("price.items.deactivate.1")}</span>{t("price.items.deactivate.2")}<br />{t("price.items.deactivate.3")}
                            <span className="text-color-high fw-700">{t("price.items.deactivate.4")}</span><br />{t("price.items.deactivate.5")}
                          </div>
                        </li>
                        <li className="is-checked">{t("price.items.whish")}</li>
                        <li className="is-checked">{t("price.items.pause.1")}<br />{t("price.items.pause.2")}</li>
                        <li className="is-checked">{t("price.items.reply.1")}<br />{t("price.items.reply.2")}</li>
                        <li className="is-checked">{t("price.items.cancle.1")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href={process.env.REACT_APP_SIGNUP_ENDPOINT} target="_blank" rel="noopener noreferrer" onClick={() => PlausibleGoalCall("ClickPricing")}>{t("price.diamond.button")}</Button>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                          {t("price.company.price")}
                        </span>
                        <span className="pricing-item-price-amount h1">
                        </span>
                        <span className="pricing-item-price-after text-xs">
                        </span>
                      </div>
                      <div className="text-xs text-color-low">
                        {t("price.company.title")} &#128200;
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        {t("price.company.paragraph")}</div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">{t("price.items.create.1")}<br />{t("price.items.create.2")}<br />{t("price.items.create.3")}</li>
                        <li className="is-checked">
                          <div>
                            <span className="text-color-high fw-700">{t("price.items.active.1.unlimited.1")}</span>{t("price.items.active.1.unlimited.2")}{t("price.items.active.2")}<br />{t("price.items.active.3")}
                          </div>
                        </li>
                        <li className="is-checked">{t("price.items.redirect")}</li>
                        <li className="is-checked">{t("price.items.adTracking")}</li>
                        <li className="is-checked">
                          <div>
                            <span className="text-color-high fw-700">{t("price.items.deactivate.1")}</span>{t("price.items.deactivate.2")}<br />{t("price.items.deactivate.3")}
                            <span className="text-color-high fw-700">{t("price.items.deactivate.4")}</span><br />{t("price.items.deactivate.5")}
                          </div>
                        </li>
                        <li className="is-checked">{t("price.items.whish")}</li>
                        <li className="is-checked">{t("price.items.pause.1")}<br />{t("price.items.pause.2")}</li>
                        <li className="is-checked">{t("price.items.reply.1")}<br />{t("price.items.reply.2")}</li>
                        <li className="is-checked">{t("price.items.domain")}</li>
                        <li className="is-checked">{t("price.items.multi")}</li>
                        <li className="is-checked">{t("price.items.replyCustomer.1")}<br />{t("price.items.replyCustomer.2")}<br />{t("price.items.replyCustomer.3")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" href="mailto:info@singlemails.com?subject=Anfrage: Singlemails für Firmen" wide>{t("price.company.button")}</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Pricing.propTypes = propTypes
Pricing.defaultProps = defaultProps

export default withTranslation()(Pricing)