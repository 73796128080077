import React from "react"
import classNames from "classnames"
import { withTranslation } from 'react-i18next'
import { SectionProps } from "../../utils/SectionProps"
import Button from "../elements/Button"
import Image from "../elements/Image"
import PlausibleGoalCall from "../../utils/Plausible"
import MailAnimation from '../elements/MailAnimation'
import Carousel from '../elements/Carousel'
import CarouselItem from '../elements/CarouselItem'

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

class HeroFull extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      t,
      ...props
    } = this.props

    const outerClasses = classNames(
      "hero section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    )

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    )

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div
              className="hero-figure reveal-from-bottom illustration-element-01"
              data-reveal-value="20px"
              data-reveal-delay="200"
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/singlemails/hero_image.png")}
                alt="Hero"
              />
            </div>
            <div
              className="hero-figure reveal-from-bottom "
              data-reveal-value="20px"
              data-reveal-delay="200"
              style={{ lineHeight: "0px" }}
            >
              <Carousel autorotate={true} autorotateTiming={3500} >
                <CarouselItem>
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/Coupon_landingPage_2_Hooray.png")}
                    alt="Hero"
                    style={{ maxWidth: "80%" }}
                  />
                </CarouselItem>
                <CarouselItem>
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/singlemails/Coupon_landingPage_3_Coupon.png")}
                    alt="Hero"
                    style={{ maxWidth: "80%" }}
                  />
                </CarouselItem>
              </Carousel>

            </div>
            <div className="hero-content">
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                <><span className="green-text"> {t('hero.claim.1')} </span>{t('hero.claim.2')}</>
              </h1>
              <MailAnimation />

              <div className="container-xs">
                <p
                  className="m-0 mb-32 reveal-from-bottom"
                  data-reveal-delay="200"
                >
                  <>{t('hero.explanation')}<br /></>
                </p>
                <div className="reveal-from-top" data-reveal-delay="200">
                  <Button tag="a" color="primary" wideMobile href={process.env.REACT_APP_SIGNUP_ENDPOINT} target="_blank" rel="noopener noreferrer" onClick={() => PlausibleGoalCall("ClickHero")}>
                    {t('hero.button')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >
    )
  }
}

HeroFull.propTypes = propTypes
HeroFull.defaultProps = defaultProps


export default withTranslation()(HeroFull)
