import React from "react"
import classNames from "classnames"
import { withTranslation } from 'react-i18next'
import { SectionSplitProps } from "../../utils/SectionProps"
import SectionHeader from "./partials/SectionHeader"
import Image from "../elements/Image"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

class DescriptionSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      t,
      i18n,
      ...props
    } = this.props

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    )

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    )

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    )

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item reveal-from-left">
                <div
                  className="split-item-content center-content-mobile"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12" name="description" >{t('description.headerMail.1')}<span className="green-text"> {t('description.headerMail.2')} </span>{t('description.headerMail.3')}</h3>
                  <p className="m-0">
                    {t('description.textMail')}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  {
                    i18n.language === "de" ?
                      <Image
                        className="has-shadow"
                        src={require("./../../assets/images/singlemails/seriousMail_de.png")}
                        alt="Honest Mail"
                      /> :
                      <Image
                        className="has-shadow"
                        src={require("./../../assets/images/singlemails/seriousMail_en.png")}
                        alt="Honest Mail"
                      />
                  }
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t('description.headerWhatIs.1')}<span className="green-text"> Singlemails?</span></h3>
                  <p className="m-0">
                    {t('description.textWhatIs.1')}<br />
                    <br />
                    {t('description.textWhatIs.2')}
                    <br />
                    <br />
                    {t('description.textWhatIs.3')}
                    <br />
                    <br />
                    {t('description.textWhatIs.4')}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image split-item-image2 center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  {
                    i18n.language === "de" ?
                      <Image
                        className="has-shadow"
                        src={require("./../../assets/images/singlemails/whatIsSinglemails_de.png")}
                        alt="What is Singlemails"
                      /> :
                      <Image
                        className="has-shadow"
                        src={require("./../../assets/images/singlemails/whatIsSinglemails_en.png")}
                        alt="What is Singlemails"
                      />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

DescriptionSplit.propTypes = propTypes
DescriptionSplit.defaultProps = defaultProps

export default withTranslation()(DescriptionSplit)
