import React from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Input from '../elements/Input'
import Button from '../elements/Button'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class SignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { login: false }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      t,
      ...props
    } = this.props

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    )

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    )

    const sectionHeader = {
      title: t("investors.title"),
    }


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label="Email"
                          placeholder="E-Mail"
                          labelHidden
                          required
                          onChange={() => this.setState({ login: false })} />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="password"
                          label="Password"
                          placeholder="Password"
                          labelHidden
                          required
                          onChange={() => this.setState({ login: false })} />
                      </div>
                      <div className="mt-24 mb-32" >
                        <a>
                          <Button color="primary"
                            style={this.state.login ? { backgroundColor: "#ee5860" } : null}
                            wide
                            onClick={() => setTimeout(() => this.setState({ login: true }), 500)}>
                            {t("investors.button")}
                          </Button>
                        </a>
                        {this.state.login &&
                          <div className="form-hint text-color-error">
                            {t("investors.message")}
                          </div>
                        }
                      </div>
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

SignupForm.propTypes = propTypes
SignupForm.defaultProps = defaultProps

export default withTranslation() (SignupForm)