import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { withTranslation, useTranslation } from 'react-i18next'
import Logo from "./partials/Logo"
import Button from '../elements/Button'
import PlausibleGoalCall from '../../utils/Plausible'

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
}

const defaultProps = {
  active: false,
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
}

class Header extends React.Component {
  state = {
    isActive: false,
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu()
    document.addEventListener("keydown", this.keyPress)
    document.addEventListener("click", this.clickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress)
    document.addEventListener("click", this.clickOutside)
    this.closeMenu()
  }

  openMenu = () => {
    document.body.classList.add("off-nav-is-active")
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + "px"
    this.setState({ isActive: true })
  };

  closeMenu = () => {
    document.body.classList.remove("off-nav-is-active")
    this.nav.current && (this.nav.current.style.maxHeight = null)
    this.setState({ isActive: false })
  };

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu()
  };

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (
      !this.state.isActive ||
      this.nav.current.contains(e.target) ||
      e.target === this.hamburger.current
    )
      return
    this.closeMenu()
  };

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      t,
      ...props
    } = this.props


    const classes = classNames(
      "site-header",
      bottomOuterDivider && "has-bottom-divider",
      className
    )

    return (
      <header {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-header-inner",
              bottomDivider && "has-bottom-divider"
            )}
          >
            <Logo showLanguage/>
            {!hideNav && (
              <React.Fragment>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">
                    {t('header.menu')}</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={classNames(
                    "header-nav",
                    this.state.isActive && "is-active"
                  )}
                >
                  <div className="header-nav-inner">
                    <ul
                      className={classNames(
                        "list-reset text-xxs",
                        navPosition && `header-nav-${navPosition}`
                      )}
                    >
                      <li className="nav-li">
                        <a alt="Features" href="/#features" onClick={this.closeMenu}>
                          {t('header.features')}
                        </a>
                      </li>
                      <li className="nav-li">
                        <a alt="Preis" href="/#price" onClick={this.closeMenu}>
                          {t('header.price')}
                        </a>
                      </li>
                      <li className="nav-li">
                        <a alt="Blog" href="https://blog.singlemails.de" target="_blank" rel="noopener noreferrer" onClick={this.closeMenu}>
                          {t('header.blog')}
                        </a>
                      </li>
                      {/* <li className="nav-li">
                        <a href="/invest" onClick={this.closeMenu}>
                          {t('header.investors')}
                        </a>
                      </li> */}
                    </ul>
                    {!hideSignin && (
                      <ul className={classNames(
                        "list-reset text-xxs",
                        navPosition && `header-nav-right`
                      )}>
                        <li>
                          <a alt="App" href={process.env.REACT_APP_SIGNUP_ENDPOINT} target="_blank" rel="noopener noreferrer" onClick={() => PlausibleGoalCall("ClickHeaderRegister")}>
                            <Button color="primary" className="button-small" >
                              {t('header.register')}
                            </Button></a>
                        </li>
                        <li>
                          <a alt="App" href={process.env.REACT_APP_APP_ENDPOINT} target="_blank" rel="noopener noreferrer" onClick={() => PlausibleGoalCall("ClickHeaderLogin")}>
                            <Button color="secondary" className="button-small" >
                              {t('header.login')}
                            </Button></a>
                        </li>
                      </ul>
                    )}
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="lineWrapper">
          <div className="greenLine" />
          <div className="pinkLine" />
        </div>
      </header >
    )
  }
}



Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default withTranslation()(Header)
