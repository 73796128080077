import React, { useState } from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Image from '../elements/Image'
import '@fortawesome/fontawesome-free'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const scaleCustomSVGStyle = { transform: "scale(0.5)" }

class FeaturesTiles extends React.Component {

  onClickDefault = () => {
    // starting the animation
    this.confetti()
  }

  getInstance = (instance) => {
    // saving the instance to an internal property
    this.confetti = instance
  }


  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    )

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    )

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    )

    const sectionHeader = {
      title: 'Was ist dir wichtig?',
      paragraph: 'Welche Eigenschaften sind dir besonders wichtig bei deiner E-Mail-Adresse? Klicke auf die Aussagen die dich am ehesten ansprechen und finde im nächsten Abschnitt heraus welche Singlemails abdeckt.',
      additionalInfo: <p><h6><i class="far fa-hand-point-up" /> Klicke auf deine wichtigsten Themen:</h6></p>,
      sectionName: "important"
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <ClickableTile
                StateKey="protection"
                callback={this.props.callback}
                Headline="Schutz"
                Text='Ich möchte gerne meine ehrliche E-Mail schützen.'
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/singlemails/protection.svg')}
                  alt="Features tile icon 01"
                  width={64}
                  height={64}
                  style={scaleCustomSVGStyle}
                />} />

              <ClickableTile
                StateKey="spam"
                callback={this.props.callback}
                Headline="Anti-Spam"
                Text="Ich mag kein Spam und es nervt gewaltig."
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/singlemails/spamMail.svg')}
                  alt="Features tile icon 02"
                  width={64}
                  height={64}
                  style={scaleCustomSVGStyle}
                />} />

              <ClickableTile
                StateKey="privacy"
                callback={this.props.callback}
                Headline="Privatsphäre"
                Text="Meine Email Adresse gehört zu meinen persönlichen Daten."
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/singlemails/privacy.svg')}
                  alt="Features tile icon 02"
                  width={64}
                  height={64}
                  style={scaleCustomSVGStyle}
                />} />

              <ClickableTile
                StateKey="consciousness"
                callback={this.props.callback}
                Headline="Bewusstsein"
                Text="Ich möchte mit der Weitergabe meiner E-Mail sparsam sein."
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/singlemails/frugal.svg')}
                  alt="Features tile icon 02"
                  width={64}
                  height={64}
                  style={scaleCustomSVGStyle}
                />} />

              <ClickableTile
                StateKey="usability"
                callback={this.props.callback}
                Headline="Anwendbarkeit"
                Text="Ich möchte meine bestehende Email weiterhin verwenden."
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/feature-tile-icon-04.svg')}
                  alt="Features tile icon 05"
                  width={64}
                  height={64} />} />

              <ClickableTile
                StateKey="simplicity"
                callback={this.props.callback}
                Headline="Simple"
                Text="Ich möchte nicht alles ändern müssen. Der Dienst muss einfach und intuitiv seien."
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/feature-tile-icon-03.svg')}
                  alt="Features tile icon 06"
                  width={64}
                  height={64} />} />

              <ClickableTile
                StateKey="tech"
                callback={this.props.callback}
                Headline="Ich bin kein Techie"
                Text="Es muss funktionieren. Langweile mich nicht mit technischen Details"
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/singlemails/network.svg')}
                  alt="Features tile icon 02"
                  width={64}
                  height={64}
                  style={scaleCustomSVGStyle}
                />} />

              <ClickableTile
                onClick={this.props.fireConfetti}
                StateKey="smart"
                callback={this.props.callback}
                Headline=" Ich bin smart"
                Text="Das können wir nur bestätigen ;)"
                Image={<Image
                  className="icon-svg"
                  src={require('./../../assets/images/feature-tile-icon-06.svg')}
                  alt="Features tile icon 06"
                  width={64}
                  height={64} />} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FeaturesTiles.propTypes = propTypes
FeaturesTiles.defaultProps = defaultProps


const ClickableTile = ({ Image, Headline, Text, StateKey, callback, onClick }) => {

  const [clicked, setClicked] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)


  return (
    <div className={
      (clicked ? "tiles-item-border-clicked" : "tiles-item-border") + " " +
      (firstLoad ? "reveal-from-bottom" : "")}
      onClick={() => {
        let isClicked = !clicked
        if (isClicked && onClick) {
          onClick()
        }
        setClicked(isClicked)
        setFirstLoad(false)
        callback(StateKey, isClicked)
      }}>
      <div className="tiles-item-inner">
        <div className="features-tiles-item-header">
          <div className="features-tiles-item-image mb-16" style={{}}>
            {Image}
          </div>
        </div>
        <div className="features-tiles-item-content">
          <h4 className="mt-0 mb-8">
            {Headline}
          </h4>
          <p className="m-0 text-sm">
            {Text}
          </p>
          <div>

          </div>
        </div>
      </div>
    </div >)
}

export default FeaturesTiles