import React, { useEffect, useState } from "react"
import Input from '../elements/Input'
import Button from '../elements/Button'
import uniqueRandomArray from "unique-random-array"
import { useTranslation } from 'react-i18next'


const mailAddresses = uniqueRandomArray(["jet-black-life", "fraudulent-weekend", "ostensive-attitude", "unconcerned-tradition", "secular-population", "dimensioning-champion", "fancied-key", "long-range-campaign", "graphical-recommendation", "split-inspector", "vanadous-tea", "beholden-green", "polyatomic-movie", "unpatented-usual",
  "self-inflicted-care", "unassisted-pay", "unbreachable-name", "liable-engineering", "frumpy-bedroom", "highest-competition", "dolabriform-reference", "unportioned-cloud", "frugivorous-department", "unmechanised-vacation", "atomism-cap", "reversed-play", "hairiest-level", "necked-type", "maximizing-function", "shocking-club", "therapeutic-member", "vain-magazine"])

const mailData = uniqueRandomArray([["Lilly Goldhaber", "lilly@goldhaber.de"],["Karin Tilgner", "karin@tilgner.de"],["Lynn Engels", "lynn@engels.de"],["Artur Schulze", "artur@schulze.de"],["Björn Steinhaeusser", "björn@steinhaeusser.de"],["Oscar Schneiderman", "oscar@schneiderman.de"]])

const fakeUser = mailData()
const mailSufix = "@singlemails.de"


const MailAnimation = () => {

  const {t} = useTranslation()

  const [currentAddress, setCurrentAddress] = useState(mailAddresses() + mailSufix)
  const [animationStart, setAnimationStart] = useState(false)


  const updateAddress = () => setCurrentAddress(mailAddresses() + mailSufix)
  const roll = (time) => {
    updateAddress()

    if (time > 750) {
      return
    } else {
      let newTime = time + (time * 0.2)
      setTimeout(() => roll(newTime), newTime)
    }
  }

  useEffect(() => {
    if (!animationStart){
      setAnimationStart(true)
      setTimeout(() => roll(25), 2000)
      setTimeout(() => setAnimationStart(false), 15000)
    }
  })


  return (
    <div className="m-0 mb-32 reveal-from-bottom">
      <div className="container-animation text-xxs ta-l">
        <div className="mb-12 ml-12 mr-12">
          {t("mailAnimation.name")}: <Input
            className="form-input-animation-disabled"
            type="name"
            label={t("mailAnimation.name")}
            placeholder={fakeUser[0]}
            labelHidden
            disabled />
        </div>
        <div className="mb-12 ml-12 mr-12">
        {t("mailAnimation.realMail")}:
          <Input
            className="form-input-animation-active"
            type="email"
            label={t("mailAnimation.realMail")}
            placeholder={fakeUser[1]}
            labelHidden
            disabled
          />
        </div>
        <div className="ml-12 mr-12 ta-c">
          {/* <i class="fas fa-link "></i> */}
          <i class="fas fa-exchange-alt fa-rotate-90"></i>
        </div>
        <div className="mb-12 ml-12 mr-12">
        {t("mailAnimation.smMail")}:
          <Input
            className="form-input-animation-active"
            type="email"
            label={t("mailAnimation.smMail")}
            placeholder={currentAddress}
            labelHidden
            disabled
          />
        </div>
        {/* <div className="mb-12 ml-12 mr-12">
          Passwort:
          <Input
            className="form-input-animation-disabled"
            type="password"
            label=""
            placeholder="*******"
            labelHidden
            disabled />
        </div>
        <div className="mb-12 ml-12 mr-12">
          Passwort Wiederholen:
          <Input
            className="form-input-animation-disabled"
            type="password"
            label="Password"
            placeholder="*******"
            labelHidden
            disabled
          />
        </div> */}
      </div>
    </div>
  )
}


export default MailAnimation