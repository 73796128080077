import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import AppRoute from './utils/AppRoute'
import ScrollReveal from './utils/ScrollReveal'

// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views 
import Home from './views/Home'
import Secondary from './views/Secondary'
import Terms from './views/Terms'
import Privacy from './views/Privacy'
import Imprint from './views/Imprint'
import Pricing from './views/Pricing'
import InvestLogin from './components/sections/InvestLogin'

import { Helmet } from "react-helmet"

import "./utils/i18n"

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    console.log("this.refs", this.refs)
    this.refs.scrollReveal.init()
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init()
    }
  }

  render() {
    console.log("ENV_VARS", process.env)

    return (
      <>
        <Helmet>
          <script defer data-domain={process.env.REACT_APP_PLAUSIBLE_DOMAIN} src="https://plausible.io/js/plausible.js"></script>
          <link rel="canonical" href={process.env.REACT_APP_DOMAIN} />
          <script src="https://kit.fontawesome.com/6a67c72606.js" crossorigin="anonymous"></script>
        </Helmet>
        <ScrollReveal
          ref="scrollReveal"
          children={() => (
            <Switch>
              <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              <AppRoute exact path="/terms" component={Terms} layout={LayoutDefault} />
              <AppRoute exact path="/privacy" component={Privacy} layout={LayoutDefault} />
              <AppRoute exact path="/imprint" component={Imprint} layout={LayoutDefault} />
              <AppRoute exact path="/impressum" component={Imprint} layout={LayoutDefault} />
              <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />
              {/* <AppRoute exact path="/secondary" component={Secondary} layout={LayoutDefault} /> */}
              {/* <AppRoute exact path="/invest" component={InvestLogin} layout={LayoutDefault} /> */}
            </Switch>
          )} />
      </>
    )
  }
}

export default withRouter(props => <App {...props} />)