import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.LOG_LEVEL === "DEBUG",
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    resources: {
      de: {
        translation: {
          "header": {
            "menu": "Menü",
            "features": "Features",
            "price": "Preis",
            "blog": "Blog",
            "investors": "Investoren",
            "register": "Registrieren",
            "login": "Log In"
          },
          "hero": {
            "claim": {
              1: "Verstecke",
              2: "deine E-Mail"
            },
            "explanation": "Mit Singlemails versteckst du deine ehrliche und private E-Mail im Handumdrehen vor Werbung, SPAM und Bösewichten.",
            "button": "Jetzt E-Mail verstecken"
          },
          "mailAnimation": {
            "name": "Name",
            "realMail": "Echte E-Mail",
            "smMail": "Singlemails E-Mail"
          },
          "description": {
            "headerMail": {
              1: "Deine",
              2: "ehrliche",
              3: "Mail"
            },
            "textMail": "Mit der ehrlichen E-Mail bezeichnen wir die private E-Mail-Adresse, die du schon eine kleine Ewigkeit besitzt und welche dir sehr ans Herz gewachsen ist. Deine ehrliche E-Mail nutzt du tagtäglich, um dich bei Online Services anzumelden, um im Internet zu shoppen, mit Freunden zu kommunizieren oder einfach online Essen zu bestellen.",
            "headerWhatIs": {
              1: "Was ist"
            },
            "textWhatIs": {
              1: "Mit Singlemails kannst du deine ehrliche E-Mail einfach hinter einer anderen E-Mail-Adresse verstecken. Du musst du deine ehrliche E-Mail-Adresse nie mehr preisgeben.",
              2: "Für jeden Anwendungsfall kannst du eine neue und einzigartige Singlemails Adresse erzeugen und diese auch nur für die Anwendung einsetzen.",
              3: "Alle an deine Singlemails Addresse gesendeten Nachrichten werden direkt an deine ehrliche E-Mail weitergeleitet.",
              4: "Somit erfährt niemand wie deine ehrliche E-Mail-Adresse lautet."
            }
          },
          "features": {
            "headerEasy": {
              1: "Einfach & Schnell",
              2: " Singlemails Adressen erstellen"
            },
            "textEasy": "Erstelle eine neuen Singlemails Adresse in Sekunden. Egal ob du Zuhause bist und einen spannenden neuen online Service entdeckt hast oder ob du im Restaurant deine Kontaktdaten eintragen sollst. Klicke den \"Singlemails Adresse erstellen\" Button und schon kannst du dir sicher sein. Nur dieser Anbieter kann deine einmalige Singlemails Adresse nutzen.",

            "headerPrivacy": {
              1: "Volle Kontrolle über deine",
              2: " Privatsphäre"
            },
            "textPrivacy": "Finde heraus wie mit deinen Daten umgegangen wird. Schreibe einen Kommentar an deine Singlemails Adresse. Wir schicken diesen bei jeder E-Mail mit. Wurde deine Singlemails Adresse weitergegeben? Du kannst es direkt erkennen.",

            "headerSpam": {
              1: "SPAM",
              2: " Schild"
            },
            "textSpam": "Eine Singlemails Adresse empfängt SPAM? Pausiere oder deaktiviere sie und fühle dich sicher. Die SPAM-Schleuder hat deine echte E-Mail-Adresse nicht bekommen. Keine Nachricht wird auf deine echte E-Mail-Adresse weitergeleitet und du kannst dir einfach einen neue Singlemails Adresse erzeugen.",

            "headerNoWork": {
              1: "Kein Einrichten",
              2: " nötig"
            },
            "textNoWork": "Um Singlemails zu nutzen, musst du keine Anpassung an deinem E-Mail-Programm machen. Einfach registrieren. Jede Singlemails Adresse sendet die empfangenen E-Mails direkt an dich weiter. Kein Vorwissen ist notwendig und alles bleibt beim Alten. Außer vielleicht, dass niemand jemals wieder deine echte E-Mail-Adresse bekommen muss.",

            "headerAds": {
              1: "Werbung,",
              2: " nein danke!"
            },
            "textAds": "Hast du unseren Cookiebanner gesehen? Nein? Genau! Unseren Traffic messen wir anonym und GDPR konform ohne Cookies. Außerdem benutzen wir keine Werbenetzwerke. Nicht auf unserer Homepage, nicht in unserer App und schon gar nicht bei deinen E-Mails.",

            "headerReply": {
              1: "Antworte",
              2 : " anonym"
            },
            "textReply" : "Antworte auf eingehende Singlemails E-Mails über deine persönliche Antwort-Adressen. Zum Beispiel, wenn du einen Online-Shop kontaktieren möchtest um eine Rücksendung zu veranlassen, einen Garantiefall hast oder einfach eine Frage stellen möchtest. Die verknüpfte Singlemails-Adresse wird als Absender angezeigt. Die andere Seite sieht niemals deine ehrliche E-Mail-Adresse."
          },
          "price": {
            "intro": {
              "title": "Preis",
              "paragraph": "Nutze unsere kostenlose Basisversion oder zahle einen kleinen monatlichen Betrag, um die maximale Sicherheit für deine private E-Mail zu bekommen. Falls du Singlemails für dein Business nutzen möchtest, dann solltest du uns kontaktieren. Wir helfen dir gerne weiter."
            },
            "items": {
              "create": {
                1: "Erstelle so viele",
                2: "Singlemails Adressen wie",
                3: "du möchtest!"
              },
              "active": {
                1: {
                  "limited": {
                    1: "Bis zu ",
                    2: "10"
                  },
                  "unlimited": {
                    1: "Unbegrenzt",
                    2: " viele"
                  },
                },
                2: " gleichzeitig",
                3: "aktive Singlemails Adressen"
              },
              "redirect": "Weiterleitung aller E-Mails",
              "adTracking": "Keine Analyse durch Werbenetzwerke",
              "deactivate": {
                1: "Deaktivierte",
                2: " Singlemails",
                3: "Adressen werden",
                4: " niemals ",
                5: " an andere Kunden verteilt!"
              },
              "whish": "Erstelle eigene Wunschadressen",
              "pause": {
                1: "Pausiere den E-Mail Empfang",
                2: " pro Singlemails Adresse"
              },
              "reply": {
                1: "Antworte auf E-Mails",
                2: "ohne deine Identität preiszugeben"
              },
              "domain": "Nutze deine eigene Domain",
              "multi": "Mehrere Nutzer registrieren",
              "replyCustomer": {
                1: "Ticketbasierte Singlemails",
                2: "Adresse für die 1:1",
                3: "Kundenkommunikation"
              },
              "cancle" : {
                1: "Abo jederzeit kündbar"
              }
            },

            "gold": {
              "title": "Gold",
              "paragraph": "Schütze deine E-Mail",
              "button": "Jetzt Registrieren",
              "price": "Kostenlos"
            },
            "diamond": {
              "title": "Diamant",
              "paragraph": "Maximale Sicherheit für deine E-Mail",
              "button": "Jetzt Registrieren",
              "price": {
                1: "4 € Monatlich",
                2: "40 € Jährlich spare - 17%"
              }
            },
            "company": {
              "title": "Für Firmen",
              "paragraph": "Erweiterte Funktionen für deine Kundenkommunikation",
              "button": "Anfrage starten",
              "price": "Auf Anfrage"
            }
          },
          "banner": {
            "register": "Jetzt registrieren",
            1: "Schütze deine E-Mail Adresse vor Trackern, Spam und Bösewichten!",
            2: {
              1: "Spare 50% auf dein Diamant Abo.",
              2: "3 Monate für je 2 €.",
              3: "Code: \"WELCOME\"",
            },
            3: "Warte nicht bis deine E-Mail Adresse im Internet verkauft wird!",
          },
          "footer": {
            "contact": "Kontakt",
            "imprint": "Impressum",
            "dataPrivacy": "Datenschutzerklärung",
            "terms": "AGB's"
          },
          "investors": {
            "title": "Investoren Login",
            "button": "Login",
            "message": "Deine E-Mail oder Passwort ist nicht korrekt!"
          }
        }
      },
      en: {
        translation: {
          "header": {
            "menu": "Menu",
            "features": "Features",
            "price": "Price",
            "blog": "Blog",
            "investors": "Investors",
            "register": "Sign Up",
            "login": "Log In"
          },
          "hero": {
            "claim": {
              1: "Hide",
              2: "your E-Mail"
            },
            "explanation": "Singlemails easily hides your honest and private e-mail from advertisements, SPAM and villains.",
            "button": "Hide your e-mail now"
          },
          "mailAnimation": {
            "name": "Name",
            "realMail": "Honest Email",
            "smMail": "Singlemails Email"
          },
          "description": {
            "headerMail": {
              1: "Your",
              2: "Honest",
              3: "e-mail"
            },
            "textMail": "Your honest e-mail is our way of calling your private e-mail address, that you are using for half an eternity now. You love your private e-mail, and you are using it every day to sign up at online services, do some online shopping, communicating with your friends or simply to order food online.",
            "headerWhatIs": {
              1: "What is"
            },
            "textWhatIs": {
              1: "Singlemails is your service to simply hide your honest e-mail behind another e-mail address. You never have to give away your honest e-mail, ever again.",
              2: "Create a new Singlemails address for every service you use and use it exclusively for that service alone.",
              3: "Every mail that is received by a Singlemails address will be redirected directly to your honest e-mail.",
              4: "Nobody will ever know your honest email address."

            }
          },
          "features": {
            "headerEasy": {
              1: "Simple & Easy",
              2: " Singlemails address creation"
            },
            "textEasy": "Create a new Singlemails address in seconds. No matter if you are at home and found a new and exciting online service or if you are in a restaurant and have to give your contact data. Just click on the \"Create Singlemails Address\" button, and you can be sure: Only this service can use your exclusive and unique Singlemails address.",

            "headerPrivacy": {
              1: "Full control over your",
              2: " Privacy"
            },
            "textPrivacy": "Find out how your online data is beeing treated. Write a comment for each of your Singlemails addresses. We send the comment along with every e-mail you receive. Has your e-mail been given away or sold by the shiny new online service? You will find out!",

            "headerSpam": {
              1: "SPAM",
              2: " Shield"
            },
            "textSpam": "One of your Singlemails addresses receives SPAM? Just pause or deactivate it and feel safe, the Spammer did not get your honest e-mail address. No mail will be sent to your honest e-mail address anymore, and you can simply create a new Singlemails address.",

            "headerNoWork": {
              1: "No Setup",
              2: " needed"
            },
            "textNoWork": "To use Singlemails, you do not have to set up anything in your e-mail program. Just sign up. Every created Singlemails address will send the received e-mails directly to you. No need for extensive knowledge. For you, everything stays the same. Except maybe, that nobody ever needs to know your honest e-mail address again.",

            "headerAds": {
              1: "Advertisment,",
              2: " no thanks!"
            },
            "textAds": "Did you read our cookie banner? No? Right! We meassure our traffic anonymously and GDPR conform without cookies. Also, no ad-networks are used. Not on this site, not in our app and especially not with your e-mails",

            "headerReply": {
              1: "Reply",
              2 : " anonymously"
            },
            "textReply" : "Reply to an incoming singlemails email. For example, if you want to contact the provider of an online store to clarify a return, a warranty claim or simply for a query. The corresponding Singlemails email-address will be displayed as the sender of your reply. The other side will never see your honest email address."
          },
          "price": {
            "intro": {
              "title": "Price",
              "paragraph": "Use our free basic version or pay a small monthly amount to get the maximum security for your private e-mail. If you want to use Singlemails for your business, contact us. We love to help you.",
            },
            "items": {
              "create": {
                1: "Create as many",
                2: "Singlemails addresses",
                3: "as you want!"
              },
              "active": {
                1: {
                  "limited": {
                    1: "Up to ",
                    2: "10"
                  },
                  "unlimited": {
                    1: "Unlimited",
                    2: " "
                  },
                },
                2: " simultaneous",
                3: "active Singlemails addresses"
              },
              "redirect": "Redirect all e-mails to your honest address",
              "adTracking": "No advertisement analysis",
              "deactivate": {
                1: "Deactivated",
                2: " Singlemails",
                3: "adresses will",
                4: " never ",
                5: " be given to other customers!"
              },
              "whish": "Choose your Singlemails address names",
              "pause": {
                1: "Pause e-mail redirection",
                2: " per Singlemails address"
              },
              "reply": {
                1: "Reply to e-mails without",
                2: "giving away your email address"
              },
              "domain": "Use your own domain",
              "multi": "Sign up multiple users",
              "replyCustomer": {
                1: "Ticket based Singlemails",
                2: "address for a 1:1",
                3: "customer comunication"
              },
              "cancle" : {
                1: "Cancel your subscription at any time"
              }
            },

            "gold": {
              "title": "Gold",
              "paragraph": "Protect your e-mail",
              "button": "Sign up now",
              "price": "Free",
            },
            "diamond": {
              "title": "Diamond",
              "paragraph": "Maximum security for your e-mail",
              "button": "Sign up now",
              "price": {
                1: "4€ monthly",
                2: "40€ yearly - save 17%"
              }
            },
            "company": {
              "title": "For Business",
              "paragraph": "Extended functionalities for your customer communication",
              "button": "make a request",
              "price": "Upon Request",
            }
          },
          "banner": {
            "register": "Sign up now",
            1: "Protect your e-mail address from trackers, SPAM and villains",
            2: {
              1: "Save 50% on your Diamond subscription.",
              2: "3 months for 2€ each.",
              3: "Code: \"WELCOME\""
            },
            3: "Control who is allowed to contact you",
          },
          "footer": {
            "contact": "Contact",
            "imprint": "Imprint",
            "dataPrivacy": "Privacy statement",
            "terms": "Terms"
          },
          "investors": {
            "title": "Investors Login",
            "button": "Login",
            "message": "Your e-mail or password is incorrect"
          }
        }
      }
    }
  }
  )

export default i18n
