import React, {useEffect} from 'react'
import GenericSection from '../components/sections/GenericSection'

const Imprint = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <GenericSection topDivider>
                <div className="container">

                    <h2 className="mt-0">Impressum</h2>
                    <p>
                        Singlemails.de wird betrieben von:<br/>
                        SingleGrey GmbH<br/>
                        Katharinenstrasse 30 A<br/>
                        20457 Hamburg<br/>
                        Telefon: +49 40 60779426<br/>
                        E-Mail: info[at]singlegrey.de<br/>
                    </p>

                    <p>
                        Vertreten durch:<br/>
                        Ali D. Bastan und Maximilian Konrädi<br/>
                    </p>

                    <p>
                        Verantwortlich im Sinne des Art. 18 MStV:<br/>
                        Ali D. Bastan und Maximilian Konrädi<br/>
                    </p>

                    <p>
                        Rechtliche Hinweise<br/>
                        Inhaltlich verantwortlich: Ali Bastan und Maximilian Konrädi<br/>
                        Erstellung der Webseite: SingleGrey GmbH<br/>
                        Bildmaterial: eigene Bilder und Designs<br/>
                    </p>

                    <p>
                        Eingetragen beim Amtsgericht Hamburg unter der Registernummer: HRB 168281<br/>
                        Umsatzsteuer-Identifikationsnummer (USt-IdNr.) gemäß § 27a Umsatzsteuergesetz: DE342813348<br/>
                    </p>


                </div>
            </GenericSection>
        </>
    )

}


export default Imprint