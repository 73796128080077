import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-01.jpg')}
                        alt="Testimonial 01"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Wow, seit ich Singlemails benutze weiß ich was um mich herum passiert.
                      Ich hätte nie gedacht, dass bekannte Firmen wirklich meine E-Mail-Adresse verkaufen.
                      Jetzt sehe ich sofort wer Serios ist und wer nicht.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Roman Level</span>
                    <span className="text-color-low"> / </span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-02.jpg')}
                        alt="Testimonial 02"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Ich mag meine E-Mail-Adresse nicht gerne überall eintragen. Gleichzeitig habe ich schon über hundert
                      Accounts erstellt. Mit Singlemails habe ich keine Angst mehr, dass ein bei einem digitalen Einbruch meine
                      E-Mail-Adresse für alle lesbar im Netz zu finden ist.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Gustavo Steely</span>
                    <span className="text-color-low"> / </span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="600">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-03.jpg')}
                        alt="Testimonial 03"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Ich nutze Singlemails wenn ich mal schnell meine E-Mail-Adresse irgendwo eingeben muss.
                      Sei es der Pizzaservice oder die Post Benachrichtigung. Das gibt mir ein besseres Gefühl.
                      Nicht jeder da draußen geht gut mit meinen Daten um. Also kümmere ich mich einfach selber um meine Privatsphäre.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Jessie Kovalev</span>
                    <span className="text-color-low"> / </span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;