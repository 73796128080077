import PlausibleTelemetry from "plausible-telemetry"

const telemetry = new PlausibleTelemetry(
  process.env.REACT_APP_PLAUSIBLE_DOMAIN
)

const PlausibleGoalCall = async (goal) => {
  await telemetry.goal(goal)
}

export default PlausibleGoalCall
